import React, { ReactElement, useCallback, useRef } from 'react';
import { Node } from '../Node';
import { usePosition } from '../Node/hooks/use-position';
import styles from './IconNode.module.css';
import { IconNodeDefaultPosition as defaultPosition } from './constants';
import { useIconDrag } from './hooks/use-drag';
import {
  determineStaticIconForDiagram,
  iconNodeHandleOnClick,
} from './utils/utils';
import { IconNodeProps } from './container';
import { NodeIconDeleteButtonContainer } from './components/DeleteButton/container';
import { NodeIconEditButtonContainer } from './components/EditButton/container';

export function IconNode({
  display,
  isReadOnly,
  uid,
  expired,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  isSnapToGridEnabled,
}: IconNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);

  const hookProps = {
    defaultPosition,
    display,
    nodeRef,
    uid,
  };

  usePosition({ ...hookProps });
  useIconDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  const renderActionButtons = useCallback(
    (isReadOnly: boolean) => {
      if (isReadOnly) return <></>;

      return (
        <div className={styles.buttons}>
          <NodeIconDeleteButtonContainer
            iconToDelete={{ name: display.name, uid }}
          />
          <NodeIconEditButtonContainer iconToEdit={{ display, uid }} />
        </div>
      );
    },
    [display, uid],
  );

  const handleOnClick = () => {
    iconNodeHandleOnClick({
      expired,
      isReadOnly,
      setLeftPanelDeviceUid,
      setLeftPanelType,
      uid,
    });
  };

  return (
    <Node
      className={styles.root}
      ref={nodeRef}
      isReadOnly={isReadOnly}
      uid={uid}
      onClick={handleOnClick}
    >
      <>
        <p className={styles.title}>{display?.name}</p>
        {determineStaticIconForDiagram({ display, uid })}
        {renderActionButtons(isReadOnly)}
      </>
    </Node>
  );
}

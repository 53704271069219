import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';

export type HandleOnHWNodeClick = {
  expired: boolean;
  setLeftPanelType: ActionCreatorWithOptionalPayload<SessionPanel | undefined>;
  setLeftPanelDeviceUid: ActionCreatorWithOptionalPayload<string | undefined>;
  isHardwareNodeInDiagramView?: string | false;
  nodeUid: string;
  assetUid?: string;
  setDeviceIdToConfigureInDiagram: ActionCreatorWithOptionalPayload<
    string | undefined
  >;
};

export const handleOnHWNodeClick = ({
  expired,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  isHardwareNodeInDiagramView,
  nodeUid,
  assetUid,
  setDeviceIdToConfigureInDiagram,
}: HandleOnHWNodeClick): void => {
  if (!expired) {
    setLeftPanelType('hardware');
    setLeftPanelDeviceUid(nodeUid);
  }

  if (isHardwareNodeInDiagramView && assetUid) {
    setDeviceIdToConfigureInDiagram(assetUid);
  }
};

import React, { ReactElement, useRef } from 'react';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import styles from './InternetCloudNode.module.css';
import { ReactComponent as InternetCloudImage } from './images/InternetCloud.svg';
import { DefaultInternetCloudNodeDisplay as defaultPosition } from './constants';

export function InternetCloudNode({
  display,
  isReadOnly,
  uid,
  isSnapToGridEnabled,
}: BaseNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();

  const hookProps = {
    defaultPosition,
    display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  return (
    <Node
      isDisabled={display?.hidden}
      className={styles.root}
      ref={nodeRef}
      isReadOnly={isReadOnly}
      uid={uid}
    >
      <p className={styles.title}>Internet</p>
      <InternetCloudImage data-component={uid} />
    </Node>
  );
}

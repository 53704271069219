import React, {
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useRef,
} from 'react';
import classnames from 'classnames';
import { useDiagramContext } from '..';
import { Node } from '../Node';
import { Handle } from '../Handle';
import { useConnection } from '../Connections/hooks/use-connection';
import { usePosition } from './hooks/use-position';
import { useDrag } from './hooks/use-drag';
import styles from './TwoHandleSideNode.module.css';
import { useResize } from './hooks/use-resize';

export function TwoHandleSideNode({
  children,
  className,
  display,
  isReadOnly,
  uid,
}: PropsWithChildren<BaseNodeProps>): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { toggleSelectedItem, selectedItem } = useDiagramContext();
  const isSelected = !isReadOnly && selectedItem === uid;

  const { redrawNodeConnections } = useConnection();
  const hookProps = { display, nodeRef, redrawNodeConnections, uid };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly });
  useResize({ ...hookProps, isReadOnly });

  return (
    <Node
      uid={uid}
      ref={nodeRef}
      className={classnames(styles.root, className, {
        [styles.selected]: isSelected,
      })}
      isHidden={display?.hidden}
      isReadOnly={isReadOnly}
      isSelected={isSelected}
      onClick={(event: MouseEvent<HTMLElement> | undefined) => {
        if (
          !event ||
          (event.target as HTMLElement).getAttribute('class') !== 'icon-pencil'
        ) {
          toggleSelectedItem(uid);
        }
      }}
      data-component={uid}
    >
      {children}
      <span className={styles.handles}>
        <Handle type="left" className={styles.handle} />
        <Handle type="right" className={styles.handle} />
      </span>
    </Node>
  );
}

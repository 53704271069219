import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from '../VmNode.module.css';
import { ReactComponent as WindowsIcon } from '../../../../../assets/images/icons/Windows.svg';
import { ReactComponent as LinuxIcon } from '../../../../../assets/images/icons/Linux.svg';
import { ReactComponent as VmwareIcon } from '../../../../../assets/images/icons/Vmware.svg';
import { ReactComponent as UCSMIcon } from '../../../../../assets/images/icons/UCSM.svg';
import {
  determineRDPEnabledNIC,
  determineSSHEnabledNIC,
} from '../../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/utils';
import i18n from '../../../../../i18n';
import { ReactComponent as Globe } from '../../../../../assets/images/icons/Globe.svg';
import { ReactComponent as CloudAccess } from '../../../../../assets/images/icons/CloudAccess.svg';

export type HandleOnEditClick = {
  isValidVmDiagramNode: boolean;
  setVmIdToConfigureInDiagram: ActionCreatorWithOptionalPayload<
    string | undefined
  >;
  uid?: string;
};

export const handleOnEditClick = ({
  isValidVmDiagramNode,
  setVmIdToConfigureInDiagram,
  uid,
}: HandleOnEditClick): void => {
  if (isValidVmDiagramNode && uid) {
    setVmIdToConfigureInDiagram(uid);
  }
};

export type HandleOnNodeClick = {
  expired: boolean;
  setLeftPanelType: ActionCreatorWithOptionalPayload<SessionPanel | undefined>;
  setLeftPanelDeviceUid: ActionCreatorWithOptionalPayload<string | undefined>;
  isValidVmDiagramNode: boolean;
  onEditClick: VoidFunction;
  uid?: string;
};

export const handleOnNodeClick = ({
  expired,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  isValidVmDiagramNode,
  onEditClick,
  uid,
}: HandleOnNodeClick): void => {
  if (!uid) return;

  if (!expired) {
    setLeftPanelType('vm');
    setLeftPanelDeviceUid(uid);
  }
  if (isValidVmDiagramNode) {
    onEditClick();
  }
};

export const renderOSIcon = (
  asset: VirtualMachine | undefined,
): ReactElement | undefined => {
  switch (asset?.osFamily) {
    case 'WINDOWS':
      return <WindowsIcon className={styles.osIcon} />;
    case 'LINUX':
      return <LinuxIcon className={styles.osIcon} />;
    case 'VMWARE':
      return <VmwareIcon className={styles.osIcon} />;
    case 'UCSM':
      return <UCSMIcon className={styles.osIcon} />;
    default:
  }
};

export const buildVmIcons = (asset?: VirtualMachine): ReactElement[] => {
  const icons = [];
  const { access } = asset || {};

  if (determineSSHEnabledNIC(asset?.vmNetworkInterfaces || [])) {
    icons.push(
      <div className={classNames(styles.ssh, styles.vmIconLabel)}>
        {i18n.t('sessions.ssh')}
      </div>,
    );
  }

  if (determineRDPEnabledNIC(asset?.vmNetworkInterfaces || [])) {
    icons.push(
      <div className={classNames(styles.rdp, styles.vmIconLabel)}>
        {i18n.t('sessions.rdp')}
      </div>,
    );
  }

  if (asset?.remoteAccess.vmConsoleEnabled) {
    icons.push(
      <div className={classNames(styles.con, styles.vmIconLabel)}>
        {i18n.t('sessions.vmConsole')}
      </div>,
    );
  }

  if (access?.publicNat || access?.haProxy) {
    icons.push(
      <div aria-label="Public NAT / HA Proxy Icon">
        <Globe className={styles.natProxyIcon} />
      </div>,
    );
  }

  if (access?.internalNat) {
    icons.push(
      <div aria-label="Internal NAT Icon">
        <CloudAccess className={styles.natProxyIcon} />
      </div>,
    );
  }

  return icons;
};

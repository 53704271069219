import {
  differenceInDays,
  intervalToDuration,
  isAfter,
  isValid,
} from 'date-fns';
import { INTERVAL_END } from './constants';

export const formatIntervalWithDays = (
  interval: Duration,
  numOfDays: number,
): string => {
  const days = formatDays(numOfDays);
  const hrs = addLeadingZeroIfRequired(interval.hours);
  const mins = addLeadingZeroIfRequired(interval.minutes);
  const secs = addLeadingZeroIfRequired(interval.seconds);
  const daysSeperator = numOfDays === 0 ? '' : ' ';

  return `${days}${daysSeperator}${hrs}:${mins}:${secs}`;
};

export const formatDays = (numOfDays: number): string => {
  return numOfDays > 0 ? `${numOfDays}d` : '';
};

export const addLeadingZeroIfRequired = (val: number | undefined): string => {
  if (!val) return '00';

  return val < 10 ? `0${val}` : val.toString();
};

export const getNumberOfDaysUntilDate = (
  startDate: Date,
  endDate: Date,
): number => {
  if (isAfter(startDate, endDate)) return 0;

  return differenceInDays(endDate, startDate);
};

export const calculateInterval = (startDate: Date, endDate: Date): Duration => {
  if (isAfter(startDate, endDate) || !isValid(startDate) || !isValid(endDate)) {
    return INTERVAL_END;
  }

  return intervalToDuration({ end: endDate, start: startDate });
};

export const getMorIdFromSessionDetails = (
  vmNodeSessionDetails: SessionVmNodeSessionDetails,
): string => {
  return vmNodeSessionDetails.mor;
};

export const getDeviceIdFromSessionDetails = (
  hwNodeSessionDetails: SessionHwNodeSessionDetails | undefined,
): number | undefined => {
  return hwNodeSessionDetails?.deviceId;
};

export const getPduControlFromSessionDetails = (
  hwNodeSessionDetails: SessionHwNodeSessionDetails | undefined,
): boolean | undefined => {
  return hwNodeSessionDetails?.pduControl;
};

export const getConsoleHrefFromSessionDetails = (
  hwNodeSessionDetails: SessionHwNodeSessionDetails | undefined,
): string | undefined => {
  return hwNodeSessionDetails?.consoleUrl;
};

export const getNameFromSessionDetails = (
  hwNodeSessionDetails: SessionHwNodeSessionDetails | undefined,
): string | undefined => {
  return hwNodeSessionDetails?.name;
};

export const getRdpFromSessionDetails = (
  vmNodeSessionDetails: SessionVmNodeSessionDetails,
): SessionVmNodeSessionDetailsRemoteAccessRDP | undefined => {
  return vmNodeSessionDetails.remoteAccess?.rdp || undefined;
};

export const getSshFromSessionDetails = (
  vmNodeSessionDetails: SessionVmNodeSessionDetails,
): SessionVmNodeSessionDetailsRemoteAccessSSH | undefined => {
  return vmNodeSessionDetails.remoteAccess?.ssh || undefined;
};

export const getUsernameFromSessionDetails = (
  vmNodeSessionDetails: SessionVmNodeSessionDetails,
): string | undefined => {
  return vmNodeSessionDetails.remoteAccess?.username || undefined;
};

export const getPasswordFromSessionDetails = (
  vmNodeSessionDetails: SessionVmNodeSessionDetails,
): string | undefined => {
  return vmNodeSessionDetails.remoteAccess?.password || undefined;
};

export const getConsoleFromSessionDetails = (
  vmNodeSessionDetails: SessionVmNodeSessionDetails,
): SessionVmNodeSessionDetailsRemoteAccessConsole | undefined => {
  return vmNodeSessionDetails.remoteAccess?.console || undefined;
};

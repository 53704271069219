import React from 'react';
import { connect } from 'react-redux';
import {
  setNetworkToConfigureInDiagram,
  setSelectedNodeToDelete,
} from '../../../../redux/diagram/slice';
import { NetworkNode } from '.';

const NetworkNodeContainer = ({
  asset,
  isReadOnly,
  setNetworkToConfigureInDiagram,
  display,
  uid,
  setSelectedNodeToDelete,
}: NetworkNodeContainerProps) => {
  return (
    <NetworkNode
      asset={asset}
      isReadOnly={isReadOnly}
      setNetworkToConfigureInDiagram={setNetworkToConfigureInDiagram}
      display={display}
      uid={uid}
      setSelectedNodeToDelete={setSelectedNodeToDelete}
    />
  );
};

const mapDispatchToProps = {
  setNetworkToConfigureInDiagram,
  setSelectedNodeToDelete,
};

type ConnectedNetworkNodeProps = BaseNodeProps & {
  asset?: Network;
};

export type NetworkNodeContainerRTKProps = typeof mapDispatchToProps;

type NetworkNodeContainerProps = ConnectedNetworkNodeProps &
  NetworkNodeContainerRTKProps;

export const ConnectedNetworkNode = connect(
  null,
  mapDispatchToProps,
)(NetworkNodeContainer);

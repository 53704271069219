import { Button } from 'dcloud-shared-ui';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import { CellProps, CellValue, Column } from 'react-table';
import { Table } from '../../../../../../../../../../components/Table';
import { Search } from '../../../../../../../../../../components/Search';
import { ArchitectureLabelGroup } from '../../../../../../../../../../components/Labels/ArchitectureLabels/ArchitectureLabelGroup';
import { filterItems } from '../../../../../../../searchUtils';
import { SEARCH_MIN_CHARS } from '../../../../../../../constants';
import { MAX_TOPOLOGY_REMOTE_ACCESS_COUNT } from '../../../../../constants';
import styles from './TemplatesTable.module.css';
import { determineWillMaxRemoteAccessCountBeExceededByIvm } from './utils';
import { TemplatesTableProps } from './container';

const VM_TEMPLATES_PAGE_SIZE_LIMIT = 15;

export function TemplatesTable({
  loading,
  templateVms,
  topologyRemoteAccessCount,
  setShowRemoteAccessCountExceededModal,
  isDiagramView = false,
  setVirtualMachineToConfigure,
}: TemplatesTableProps): ReactElement {
  const [filteredItems, setFilteredItems] =
    useState<InventoryVirtualMachine[]>(templateVms);
  const [searchValue, setSearchValue] = useState<string>('');

  const { t } = useTranslation();
  const { uid: topologyUid } = useParams<EditTopologyParams>();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const filterKeys = ['name', 'description', 'architectureNames'];
  function handleSearchChange(newSearchValue: string): void {
    setSearchValue(newSearchValue);
    if (newSearchValue.length === 0) {
      setFilteredItems(templateVms);
    } else if (newSearchValue.length >= SEARCH_MIN_CHARS) {
      const searchText = newSearchValue.toLowerCase();
      const result = filterItems({ filterKeys, list: templateVms, searchText });
      setFilteredItems(result);
    }
  }

  const willMaxRemoteAccessCountBeExceededByIvm = useCallback(
    (
      currentTopologyRemoteAccessCount: number,
      vm: InventoryVirtualMachine,
    ): boolean =>
      determineWillMaxRemoteAccessCountBeExceededByIvm(
        currentTopologyRemoteAccessCount,
        vm,
        MAX_TOPOLOGY_REMOTE_ACCESS_COUNT,
      ),
    [],
  );

  const renderName = useCallback(
    (invVm: InventoryVirtualMachine, value: CellValue): ReactElement => {
      return willMaxRemoteAccessCountBeExceededByIvm(
        topologyRemoteAccessCount,
        invVm,
      ) ? (
        <Button
          colour="link"
          onClick={() =>
            setShowRemoteAccessCountExceededModal &&
            setShowRemoteAccessCountExceededModal(true)
          }
          className={styles.nameButton}
        >
          {value}
        </Button>
      ) : isDiagramView ? (
        <Button
          colour="link"
          onClick={() => setVirtualMachineToConfigure(invVm)}
          className={styles.nameButton}
        >
          {value}
        </Button>
      ) : (
        <Link
          to={`${url.replace(':uid', topologyUid)}/configure`}
          onClick={() => dispatch(setVirtualMachineToConfigure(invVm))}
          className={styles.nameLink}
        >
          {value}
        </Link>
      );
    },
    [
      dispatch,
      isDiagramView,
      setShowRemoteAccessCountExceededModal,
      setVirtualMachineToConfigure,
      topologyRemoteAccessCount,
      topologyUid,
      url,
      willMaxRemoteAccessCountBeExceededByIvm,
    ],
  );

  const columns = useMemo<Column<InventoryVirtualMachine>[]>(
    () => [
      {
        Cell: ({ row, value }: CellProps<InventoryVirtualMachine>) =>
          renderName(row.original, value),
        Header: () => t('inventoryVirtualMachines.table.headings.name'),
        accessor: 'name',
        minWidth: 200,
      },
      {
        Header: () => t('inventoryVirtualMachines.table.headings.description'),
        accessor: 'description',
        minWidth: 200,
      },
      {
        Header: () => t('inventoryVirtualMachines.table.headings.username'),
        accessor: 'username',
      },
      {
        Header: () => t('inventoryVirtualMachines.table.headings.password'),
        accessor: 'password',
      },
      {
        Header: () => t('inventoryVirtualMachines.table.headings.managementIp'),
        accessor: 'managementIp',
        maxWidth: 125,
        minWidth: 125,
      },
      {
        Cell: ({ row }: CellProps<InventoryVirtualMachine>) =>
          row.original.architectureNames ? (
            <ArchitectureLabelGroup
              architectures={row.original.architectureNames}
            />
          ) : null,
        Header: () => t('inventoryVirtualMachines.table.headings.architecture'),
        accessor: 'architectures',
      },
    ],
    [renderName, t],
  );

  return (
    <>
      <div className="row flex-right">
        <Search
          className="base-padding"
          id="add-vm-template-search"
          onChange={(e) => handleSearchChange(e.target.value)}
          placeholder={t('common.search')}
          value={searchValue}
        />
      </div>
      <Table<InventoryVirtualMachine>
        ariaLabel="VM Templates Table"
        columns={columns}
        loading={loading}
        data={filteredItems}
        initialState={{
          pageSize: VM_TEMPLATES_PAGE_SIZE_LIMIT,
          sortBy: [{ desc: false, id: 'name' }],
        }}
      />
    </>
  );
}

import React, { KeyboardEvent, ReactElement, useRef } from 'react';
import classnames from 'classnames';
import { Icon } from 'dcloud-shared-ui';
import { useTranslation } from 'react-i18next';
import { useDiagramContext } from '..';
import { Node } from '../Node';
import { onNodeElementKeyUp } from '../utils/utils';
import { RotateIcon } from '../../../../components/RotateIcon';
import { usePosition } from './hooks/use-position';
import styles from './DiagramTextNode.module.css';
import { useDrag } from './hooks/use-drag';
import { DiagramTextNodeProps } from './container';
import {
  generateClassNamesForTextStyling,
  buildFontSizeStyle,
} from './utils/utils';

export function DiagramTextNode({
  uid,
  display,
  isReadOnly,
  deleteNode,
  setTextNodeToConfigureInDiagram,
  isSnapToGridEnabled,
}: DiagramTextNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { toggleSelectedItem, selectedItem } = useDiagramContext();
  const isSelected = !isReadOnly && selectedItem === uid;
  const hookProps = {
    display: display,
    nodeRef,
    uid,
  };
  const { t } = useTranslation();

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  return (
    <Node
      onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
        onNodeElementKeyUp({
          deleteNode,
          element: nodeRef?.current,
          isReadOnly,
          isSelected,
          keyName: event.key,
          toggleSelectedItem,
          uid,
        });
      }}
      uid={uid}
      ref={nodeRef}
      className={styles.diagramTextNode}
      isDisabled={display.hidden}
      isReadOnly={isReadOnly}
      isSelected={isSelected}
      onClick={() => toggleSelectedItem(uid)}
      data-component={uid}
      isRemovable={true}
    >
      <div
        data-component-text={uid}
        className={classnames(styles.textNodeText, {
          [styles.selected]: isSelected,
        })}
      >
        <div
          className={classnames(
            styles.label,
            generateClassNamesForTextStyling(display.textColor),
          )}
          style={buildFontSizeStyle(display.fontSize)}
        >
          {display.text}
        </div>
        {!isReadOnly && (
          <div
            className={styles.editIconWrapper}
            onClick={() => setTextNodeToConfigureInDiagram({ display, uid })}
            data-testid="edit-diagram-text"
          >
            <Icon name="pencil" />
          </div>
        )}
        {!isReadOnly && selectedItem === uid && (
          <div className={styles.rotateIcon}>
            <RotateIcon
              title={t(
                'diagram.sideMenu.addText.forms.edit.inputs.rotate.title',
              )}
            />
          </div>
        )}
      </div>
    </Node>
  );
}

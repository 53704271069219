import { dcloudApi } from '../../../../api';
import {
  SESSIONS_PATH,
  SESSIONS_SAVE_PATH,
  SESSIONS_TXT_RECORDS,
  SESSION_ACTIONS_PATH,
  SESSION_HW_ACTIONS_PATH,
  SESSION_SHARE,
  SESSION_VM_ACTIONS_PATH,
  VM_STATUS,
} from '../../../../../api/paths';
import i18n from '../../../../../i18n';
import { TAGS } from '../../../../api/constants';
import { getVmActionMessage } from '../utils';
import { setSharedWithUsers } from '../slice';

export const sessionApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    createSessionDnsRecord: build.mutation<DnsTxtRecord, DnsTxtRecord>({
      invalidatesTags: [TAGS.SESSIONS],
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t(
          'sessions.panels.info.dns.addForm.errors.onCreate',
        ),
        method: 'post',
        successMessage: i18n.t(
          'sessions.panels.info.dns.addForm.messages.success',
        ),
        url: SESSIONS_TXT_RECORDS,
      }),
    }),
    getSession: build.query<Session, FetchSessionArgs>({
      keepUnusedDataFor: 0,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const sharedUsers = data?.sessionDetails.sharedWith.map((user) => {
            return {
              email: user.userId,
            };
          });
          dispatch(setSharedWithUsers(sharedUsers));
        } catch (err) {
          return;
        }
      },
      providesTags: [TAGS.SESSIONS],
      query: ({ sessionUid, versionUid }) => ({
        errorMessage: i18n.t('sessions.fetch.error'),
        method: 'get',
        url: `${SESSIONS_PATH}/${sessionUid}?versionUid=${versionUid}`,
      }),
    }),
    getSessionVmStatus: build.query<SessionVmStatus, FetchSessionVmStatusArgs>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SESSION_VM_STATUS],
      query: ({ sessionUid, versionUid, mor }) => ({
        errorMessage: i18n.t('sessions.fetchVmStatus.error'),
        method: 'get',
        url: `${SESSIONS_PATH}/${sessionUid}${VM_STATUS}?versionUid=${versionUid}&mor=${mor}`,
      }),
    }),
    getSessionVmStatusForSessionInventory: build.query<
      SessionVmStatus,
      FetchSessionVmStatusArgs
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SESSION_VM_STATUS],
      query: ({ sessionUid, versionUid, mor }) => ({
        errorMessage: i18n.t('sessions.fetchVmStatus.error'),
        method: 'get',
        url: `${SESSIONS_PATH}/${sessionUid}${VM_STATUS}?versionUid=${versionUid}&mor=${mor}`,
      }),
    }),
    postSessionAction: build.mutation<SessionActionResponse, SessionActionArgs>(
      {
        invalidatesTags: [TAGS.SESSIONS, TAGS.SESSION_VM_STATUS],
        query: (payload) => ({
          data: payload,
          errorMessage:
            payload.action === 'RESET'
              ? i18n.t('sessions.modals.resetSession.error')
              : i18n.t('sessions.modals.endSession.error'),
          method: 'post',
          successMessage:
            payload.action === 'RESET'
              ? i18n.t('sessions.modals.resetSession.success')
              : i18n.t('sessions.modals.endSession.success'),
          url: SESSION_ACTIONS_PATH,
        }),
      },
    ),
    postSessionHwAction: build.mutation<
      SessionHwActionResponse,
      SessionHwActionArgs
    >({
      query: ({ suppressSuccessToast, suppressErrorToast, ...rest }) => ({
        data: rest,
        errorMessage: i18n.t('sessions.postHwAction.error'),
        method: 'post',
        successMessage: suppressSuccessToast
          ? undefined
          : i18n.t('sessions.postHwAction.success'),
        suppressErrorToast: suppressErrorToast,
        url: SESSION_HW_ACTIONS_PATH,
      }),
    }),
    postSessionVmAction: build.mutation<
      SessionVmActionResponse,
      SessionVmActionArgs
    >({
      query: ({ suppressSuccessToast, suppressErrorToast, ...rest }) => ({
        data: rest,
        errorMessage: i18n.t('sessions.postVmAction.error'),
        method: 'post',
        successMessage: suppressSuccessToast
          ? undefined
          : getVmActionMessage(rest.action),
        suppressErrorToast: suppressErrorToast,
        url: SESSION_VM_ACTIONS_PATH,
      }),
    }),
    saveAndEndSession: build.mutation<Session, SaveAndEndSessionArgs>({
      invalidatesTags: [TAGS.SESSIONS, TAGS.SESSION_VM_STATUS],
      query: (session) => ({
        data: session,
        errorMessage: i18n.t('sessions.forms.saveAndEnd.error'),
        method: 'POST',
        successMessage: i18n.t('sessions.forms.saveAndEnd.success'),
        url: SESSIONS_SAVE_PATH,
      }),
    }),
    shareSession: build.mutation<Session, ShareSessionPayload>({
      query: (payload) => ({
        data: payload,
        errorMessage: i18n.t('sessions.forms.share.error'),
        method: 'post',
        successMessage: i18n.t('sessions.forms.share.success'),
        url: SESSION_SHARE,
      }),
    }),
    updateSession: build.mutation<Session, UpdateSessionPayload>({
      invalidatesTags: [TAGS.SESSIONS],
      query: (session) => ({
        data: session.sessionDetails,
        errorMessage: i18n.t('sessions.forms.rename.newName.error'),
        method: 'PUT',
        successMessage: i18n.t('sessions.forms.rename.newName.success'),
        url: `${SESSIONS_PATH}/${session.sessionId}?versionUid=${session.topologyVersion.uid}`,
      }),
    }),
  }),
});

export const {
  useCreateSessionDnsRecordMutation,
  useGetSessionQuery,
  useGetSessionVmStatusQuery,
  useGetSessionVmStatusForSessionInventoryQuery,
  usePostSessionActionMutation,
  usePostSessionHwActionMutation,
  usePostSessionVmActionMutation,
  useSaveAndEndSessionMutation,
  useShareSessionMutation,
  useUpdateSessionMutation,
} = sessionApi;

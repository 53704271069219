import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export type HandleOnEditNetworkClick = {
  isNetworkNodeInDiagramView: string | false | undefined;
  setNetworkToConfigureInDiagram: ActionCreatorWithPayload<Network | undefined>;
  asset?: Network;
};

export const handleOnEditNetworkClick = ({
  asset,
  isNetworkNodeInDiagramView,
  setNetworkToConfigureInDiagram,
}: HandleOnEditNetworkClick): void => {
  if (!asset) return;

  if (isNetworkNodeInDiagramView) {
    setNetworkToConfigureInDiagram(asset);
  }
};

import React, { ReactElement } from 'react';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { NodeActionButtons } from '../../Diagram/components/NodeActionButtons';

type BuildSetSelectedNodeToDeletePayload = {
  uid: SelectedNodeToDelete['uid'];
  assetType: SelectedNodeToDelete['assetType'];
  name?: SelectedNodeToDelete['name'];
};

export const buildSetSelectedNodeToDeletePayload = ({
  uid,
  assetType,
  name,
}: BuildSetSelectedNodeToDeletePayload): SelectedNodeToDelete => ({
  assetType,
  name: name || '',
  uid,
});

export type HandleOnNodeDeleteClick = {
  setSelectedNodeToDelete: ActionCreatorWithOptionalPayload<
    SelectedNodeToDelete | undefined
  >;
  uid?: SelectedNodeToDelete['uid'];
  name?: SelectedNodeToDelete['name'];
  assetType: SelectedNodeToDelete['assetType'];
};

export const handleOnNodeDeleteClick = ({
  setSelectedNodeToDelete,
  name,
  uid,
  assetType,
}: HandleOnNodeDeleteClick): void => {
  if (!uid) return;

  const payload = buildSetSelectedNodeToDeletePayload({
    assetType,
    name: name || '',
    uid,
  });
  setSelectedNodeToDelete(payload);
};

export type RenderNodeActionButtonsHelper = {
  onEditClick: VoidFunction;
  onDeleteClick: VoidFunction;
  name?: string;
  shouldRender?: string | boolean;
};

export const renderNodeActionButtonsHelper = ({
  onEditClick,
  onDeleteClick,
  name,
  shouldRender,
}: RenderNodeActionButtonsHelper): ReactElement => {
  if (!shouldRender) return <></>;

  return (
    <NodeActionButtons
      onEditClick={onEditClick}
      onDeleteClick={onDeleteClick}
      name={name || ''}
    />
  );
};

export type OnNodeElementKeyUp = {
  keyName: string;
  isReadOnly: boolean;
  isSelected: boolean;
  deleteNode: (uid: string) => void;
  toggleSelectedItem: (uid: string) => void;
  uid: string;
  element?: HTMLDivElement | null;
};

export const onNodeElementKeyUp = ({
  keyName,
  isReadOnly,
  isSelected,
  element,
  deleteNode,
  uid,
  toggleSelectedItem,
}: OnNodeElementKeyUp): void => {
  const isValidKeyPressed = ['Backspace', 'Delete'].includes(keyName);

  if (isValidKeyPressed && isSelected && !isReadOnly && element) {
    deleteNode(uid);
    element.remove();
    toggleSelectedItem(uid);
  }
};

import React, { ReactElement } from 'react';

interface ConnectionLineProps {
  connection: DiagramConnection;
}

export const ConnectionLine = ({
  connection,
}: ConnectionLineProps): ReactElement | null => {
  let opacity = '100%';
  if (connection.display?.hidden) {
    opacity = '40%';
  }
  if (connection.display?.invisible) {
    opacity = '0%';
  }
  return (
    <line
      stroke={connection.display?.color || 'var(--cui-color-sky)'}
      strokeWidth="2"
      opacity={opacity}
      data-uid={connection.uid}
      data-connection-from={connection.nodes.from.uid}
      data-connection-to={connection.nodes.to.uid}
    ></line>
  );
};

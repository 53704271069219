import { useCallback, useEffect } from 'react';
import * as d3 from 'd3';
import { setXY, x, y, zoom } from '../../../d3';
import { useWindowResize } from '../../../../../hooks/use-window-resize';

interface UsePosition {
  display: DiagramBoxDisplay;
  nodeRef: React.RefObject<HTMLDivElement>;
  uid: string;
}

export function usePosition({ display, nodeRef, uid }: UsePosition): void {
  const position = useCallback(() => {
    d3.select<HTMLDivElement, DiagramNode>(nodeRef.current!)
      .datum(display)
      .attr('style', function (d) {
        const transform = d3.zoomTransform(this);
        const zx = transform.rescaleX(x);
        const zy = transform.rescaleY(y);
        const displayX = d.x;
        const displayY = d.y;
        const width = d.width;
        const height = d.height;
        const tx = zx(displayX);
        const ty = zy(displayY);
        return `transform: translate(${tx}px, ${ty}px) scale(${transform.k}); width: ${width}px; height: ${height}px`;
      });
  }, [display, nodeRef]);

  useWindowResize(() => {
    setXY();
    position();
  });

  useEffect(() => {
    setXY();
    position();

    zoom.on(`zoom.${uid}`, function () {
      position();
    });

    return () => {
      zoom.on(`zoom.${uid}`, null);
    };
  }, [display, position, uid]);
}

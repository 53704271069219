interface updateNodeDisplayReducerProps {
  diagramResponse: DiagramResponse;
  display: DiagramDisplay;
  nodeUid: DiagramNode['uid'];
}

interface addNodeToNodeDisplayReducerProps {
  node: DiagramNode;
  diagramResponse: DiagramResponse;
}

export function updateNodeDisplayReducer({
  diagramResponse,
  display,
  nodeUid,
}: updateNodeDisplayReducerProps): DiagramResponse {
  const newNodes = diagramResponse.nodes.map((node) => {
    if (node.uid !== nodeUid) return node;

    return { ...node, display };
  });
  return { ...diagramResponse, nodes: newNodes };
}

export function addNodeToNodeDisplayReducer({
  diagramResponse,
  node,
}: addNodeToNodeDisplayReducerProps): DiagramResponse {
  return {
    ...diagramResponse,
    nodes: [...diagramResponse.nodes, { ...node }],
  };
}

import React, { ReactElement, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { usePosition } from '../Node/hooks/use-position';
import styles from './AnyconnectNode.module.css';
import { ReactComponent as LaptopIcon } from './images/Laptop.svg';
import { ReactComponent as AnyconnectIcon } from './images/Anyconnect.svg';
import { DefaultAnyconnectNodeDisplay as defaultPosition } from './constants';

export function AnyconnectNode({
  uid,
  display,
  isReadOnly,
  isSnapToGridEnabled,
}: BaseNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();
  const { t } = useTranslation();

  const hookProps = {
    defaultPosition,
    display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });

  return (
    <Node
      isDisabled={display?.hidden}
      className={styles.root}
      ref={nodeRef}
      isReadOnly={isReadOnly}
      uid={uid}
    >
      <p className={styles.title}>{t('diagram.anyconnect')}</p>
      <LaptopIcon data-component={uid} />
      <AnyconnectIcon className={styles.anyconnectIcon} />
    </Node>
  );
}

type UpdateNodeHelper = {
  updateNode: RTKMutation<UpdateDiagramNodeProps, UpdateDiagramNodeProps>;
  display?: DiagramDisplay;
  uid: string;
  x1: number;
  y1: number;
};

export const updateNodeHelper = ({
  updateNode,
  display,
  uid,
  x1,
  y1,
}: UpdateNodeHelper): void =>
  updateNode({
    display: {
      hidden: display?.hidden,
      icon: display?.icon,
      x1,
      y1,
    },
    nodeUid: uid,
  });

/* eslint react/display-name:0, react/prop-types: 0 */
import React, {
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import classnames from 'classnames';
import styles from './Node.module.css';

interface NodeProps {
  className?: string;
  uid?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  onClick?: (event: MouseEvent<HTMLElement> | undefined) => void;
  isReadOnly: boolean;
  onKeyUp?: (event: KeyboardEvent<HTMLInputElement>) => void;
  isRemovable?: boolean;
  isDrawingType?: boolean;
  isBoxNode?: boolean;
  isVmNode?: boolean;
  isLineNode?: boolean;
}

export const Node = forwardRef<HTMLDivElement, PropsWithChildren<NodeProps>>(
  (
    {
      className,
      children,
      onClick,
      isReadOnly,
      isDisabled = false,
      isHidden = false,
      isSelected = false,
      uid,
      onKeyUp,
      isRemovable,
      isDrawingType,
      isBoxNode,
      isVmNode,
      isLineNode,
    },
    ref,
  ) => {
    const removableNodeProps = isRemovable ? { tabIndex: 0 } : {};
    return (
      <div
        {...removableNodeProps}
        onKeyUp={onKeyUp}
        data-component={uid}
        className={classnames(styles.root, className, {
          [styles.editable]: !isReadOnly,
          [styles.selected]: isSelected,
          [styles.boxNode]: isBoxNode,
          [styles.disabled]: isDisabled,
          [styles.hidden]: isHidden,
          [styles.removableNode]: isRemovable,
          [styles.drawNode]: isDrawingType,
          [styles.noHover]: isDrawingType && isReadOnly,
          [styles.vmNode]: isVmNode,
          [styles.lineNode]: isLineNode,
        })}
        ref={ref}
        onClick={onClick}
      >
        {children}
      </div>
    );
  },
);

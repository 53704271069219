import { connect } from 'react-redux';
import {
  sessionViewSelectors,
  setLeftPanelDeviceUid,
  setLeftPanelType,
} from '../../../../redux/views/session-view/session/slice';
import { RootState } from '../../../../redux/store';
import {
  diagramSelectors,
  setSelectedNodeToDelete,
  setVmIdToConfigureInDiagram,
} from '../../../../redux/diagram/slice';
import { VmNode } from '.';

const mapDispatchToProps = {
  setLeftPanelDeviceUid,
  setLeftPanelType,
  setSelectedNodeToDelete,
  setVmIdToConfigureInDiagram,
};

const mapStateToProps = (state: RootState) => ({
  expired: sessionViewSelectors.getExpired(state),
  isSnapToGridEnabled: diagramSelectors.getIsSnapToGridEnabled(state),
});

interface VmNodeAssetProps extends BaseNodeProps {
  asset?: VirtualMachine;
}

export type VMNodeProps = typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps> &
  VmNodeAssetProps;

export const VMNodeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VmNode);

import React, { ReactElement, useRef } from 'react';
import classNames from 'classnames';
import { Node } from '../Node';
import { useConnection } from '../Connections/hooks/use-connection';
import { useDrag } from '../Node/hooks/use-drag';
import { getSelectedIcon } from '../../../EditTopology/configuration/components/VirtualMachineEntities/Edit/components/GeneralForm/utils';
import { usePosition } from '../Node/hooks/use-position';
import {
  handleOnNodeDeleteClick,
  renderNodeActionButtonsHelper,
} from '../utils/utils';
import styles from './HardwareNode.module.css';
import { DefaultHardwareNodeDisplay as defaultPosition } from './constants';
import { HardwareNodeProps } from './container';
import { handleOnHWNodeClick } from './utils/utils';

export function HardwareNode({
  uid,
  asset,
  isReadOnly,
  setLeftPanelDeviceUid,
  setLeftPanelType,
  expired,
  setDeviceIdToConfigureInDiagram,
  setSelectedNodeToDelete,
  isSnapToGridEnabled,
  ...node
}: HardwareNodeProps): ReactElement {
  const nodeRef = useRef<HTMLDivElement>(null);
  const { redrawNodeConnections } = useConnection();

  const hookProps = {
    defaultPosition,
    display: node.display,
    nodeRef,
    redrawNodeConnections,
    uid,
  };

  usePosition({ ...hookProps });
  useDrag({ ...hookProps, isReadOnly, isSnapToGridEnabled });
  const isHardwareNodeInDiagramView = !isReadOnly && asset?.uid;

  const onNodeClick = () => {
    handleOnHWNodeClick({
      assetUid: asset?.uid,
      expired,
      isHardwareNodeInDiagramView,
      nodeUid: uid,
      setDeviceIdToConfigureInDiagram,
      setLeftPanelDeviceUid,
      setLeftPanelType,
    });
  };

  const onDeleteClick = () => {
    handleOnNodeDeleteClick({
      assetType: 'HARDWARE_ITEM',
      name: asset?.name,
      setSelectedNodeToDelete,
      uid: asset?.uid,
    });
  };

  const onEditClick = () => {
    onNodeClick();
  };

  const renderHWNodeActionButtons = () => {
    return renderNodeActionButtonsHelper({
      name: asset?.name || '',
      onDeleteClick,
      onEditClick,
      shouldRender: isHardwareNodeInDiagramView,
    });
  };

  return (
    <Node
      isDisabled={hookProps.display?.hidden}
      className={classNames(
        styles.root,
        isHardwareNodeInDiagramView && styles.glowNode,
      )}
      ref={nodeRef}
      isReadOnly={isReadOnly}
      onClick={() => onNodeClick()}
      uid={uid}
    >
      <p className={styles.title}>{asset?.name}</p>
      <div className={styles.actionButtonsWrapper}>
        {renderHWNodeActionButtons()}
      </div>
      <div className={styles.iconsWrapper}>
        {getSelectedIcon(hookProps.display?.icon, uid, styles.glowIcon)}
      </div>
    </Node>
  );
}

import { AxiosResponse } from 'axios';
import i18n from '../../../../i18n';
import { parseFileName } from '../../../../pages/Views/SessionView/components/Panels/DocumentationPanel/components/Documents/components/Attachment/utils';
import { SESSION_VM_ACTIONS } from '../../../../pages/Views/SessionView/constants';

export const getVmActionMessage = (action: SessionVmActions): string =>
  i18n
    .t('sessions.postVmAction.success')
    .replace(':action', SESSION_VM_ACTIONS[action]);

export function addLinkToDomAndClick(
  response: AxiosResponse<Blob>,
  documentLink: HTMLAnchorElement | undefined,
): void {
  const contentType = response.headers['content-type'];
  const file = new Blob([response.data], { type: contentType });
  documentLink = document.createElement('a');
  documentLink.href = window.URL.createObjectURL(file);
  documentLink.download = parseFileName(
    response.headers['content-disposition'],
  );
  documentLink.click();
}

export function removeObjectUrl(
  documentLink: HTMLAnchorElement | undefined,
): void {
  documentLink && window.URL.revokeObjectURL(documentLink.href);
}

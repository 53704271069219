import { dcloudApi } from '../../../../api';
import {
  EXPO_ENGAGEMENTS,
  SESSION_HW_ACTIONS_PATH,
  SESSION_VM_ACTIONS_PATH,
  SESSIONS_PATH,
  VM_STATUS,
} from '../../../../../api/paths';
import i18n from '../../../../../i18n';
import { TAGS } from '../../../../api/constants';
import { getVmActionMessage } from '../utils';

export const expoSessionApi = dcloudApi.injectEndpoints({
  endpoints: (build) => ({
    getExpoSession: build.query<Session, FetchExpoSessionArgs>({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SESSIONS],
      query: ({ engagementUid, sessionUid, versionUid }) => ({
        errorMessage: i18n.t('sessions.fetch.error'),
        method: 'get',
        url: `${EXPO_ENGAGEMENTS}${SESSIONS_PATH}/${sessionUid}?versionUid=${versionUid}&engagementId=${engagementUid}`,
      }),
    }),
    getExpoSessionVmStatus: build.query<
      SessionVmStatus,
      FetchExpoSessionVmStatusArgs
    >({
      keepUnusedDataFor: 0,
      providesTags: [TAGS.SESSION_VM_STATUS],
      query: ({ engagementUid, sessionUid, versionUid, mor }) => ({
        errorMessage: i18n.t('sessions.fetchVmStatus.error'),
        method: 'get',
        url: `${EXPO_ENGAGEMENTS}${SESSIONS_PATH}/${sessionUid}${VM_STATUS}?versionUid=${versionUid}&mor=${mor}&engagementId=${engagementUid}`,
      }),
    }),
    postExpoSessionHwAction: build.mutation<
      SessionHwActionResponse,
      ExpoSessionHwActionArgs
    >({
      query: ({
        engagementId,
        suppressSuccessToast,
        suppressErrorToast,
        ...rest
      }) => ({
        data: rest,
        errorMessage: i18n.t('sessions.postHwAction.error'),
        method: 'post',
        successMessage: suppressSuccessToast
          ? undefined
          : i18n.t('sessions.postHwAction.success'),
        suppressErrorToast: suppressErrorToast,
        url: `${EXPO_ENGAGEMENTS}${SESSION_HW_ACTIONS_PATH}?engagementId=${engagementId}`,
      }),
    }),
    postExpoSessionVmAction: build.mutation<
      SessionVmActionResponse,
      ExpoSessionVmActionArgs
    >({
      query: ({
        engagementUid,
        suppressSuccessToast,
        suppressErrorToast,
        ...rest
      }) => ({
        data: rest,
        errorMessage: i18n.t('sessions.postVmAction.error'),
        method: 'post',
        successMessage: suppressSuccessToast
          ? undefined
          : getVmActionMessage(rest.action),
        suppressErrorToast: suppressErrorToast,
        url: `${EXPO_ENGAGEMENTS}${SESSION_VM_ACTIONS_PATH}?engagementId=${engagementUid}`,
      }),
    }),
  }),
});

export const {
  useGetExpoSessionQuery,
  useGetExpoSessionVmStatusQuery,
  usePostExpoSessionHwActionMutation,
  usePostExpoSessionVmActionMutation,
} = expoSessionApi;
